import { FooterImagesContainer, FooterImages, FooterContainer } from './styled';

import ue from '../../../img/ue.svg';
import rim from '../../../img/rim.svg';
import CopyrightMessage from '../../Copyright';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterImagesContainer>
        <FooterImages src={rim} alt="logo rim" />
        <FooterImages src={ue} alt="logo ue" />
      </FooterImagesContainer>
      <CopyrightMessage />
    </FooterContainer>
  );
};

export default Footer;
