import { useState } from 'react';
import {
  Link,
  MainContainer,
  MainImage,
  HoverDiv,
  HoverTitle,
  HoverImage,
  MainImageMobile,
  ContainerMobile,
  ButtonMobile,
  ContentMobile,
  LogoMobile,
  LinkMobile,
} from './styled';
import domek from '../../../img/domek-min.png';
import kamper from '../../../img/kamper-min.png';
import logo from '../../../img/logo.png';

const Main = () => {
  const currentLeft = false;
  const currentRight = false;
  const [isShownLeft, setShownLeft] = useState(currentLeft);
  const [isShownRight, setShownRight] = useState(currentRight);

  const hoverHandleLeft = () => {
    setShownLeft((currentLeft) => !currentLeft);
  };
  const hoverHandleRight = () => {
    setShownRight((currentRight) => !currentRight);
  };

  return (
    <MainContainer>
      <Link
        href="https://www.booking.com/hotel/pl/ryby-w-sieci.pl.html"
        target={'_blank'}
        rel={'noreferrer'}
        onMouseEnter={hoverHandleLeft}
        onMouseLeave={hoverHandleLeft}
      >
        {isShownLeft ? (
          <HoverDiv>
            <HoverImage src={logo} />
            <HoverTitle>Zobacz więcej!</HoverTitle>
          </HoverDiv>
        ) : null}
        <MainImage src={domek} />
      </Link>
      <ContainerMobile>
        <ContentMobile>
          <LogoMobile src={logo} />
          <LinkMobile
            href="https://www.booking.com/hotel/pl/ryby-w-sieci.pl.html"
            target={'_blank'}
            rel={'noreferrer'}
          >
            <ButtonMobile>Zobacz więcej!</ButtonMobile>
          </LinkMobile>
        </ContentMobile>
        <MainImageMobile src={domek} />
      </ContainerMobile>
      <Link
        href="https://kamperypuck.pl/1-dethleffs-just-t-7052-eb-2/"
        target={'_blank'}
        rel={'noreferrer'}
        onMouseEnter={hoverHandleRight}
        onMouseLeave={hoverHandleRight}
      >
        {isShownRight ? (
          <HoverDiv>
            <HoverTitle>Zobacz więcej!</HoverTitle>
          </HoverDiv>
        ) : null}
        <MainImage src={kamper} />
      </Link>
      <ContainerMobile>
        <ContentMobile>
          <LinkMobile
            href="https://kamperypuck.pl/1-dethleffs-just-t-7052-eb-2/"
            target={'_blank'}
            rel={'noreferrer'}
          >
            <ButtonMobile>Zobacz więcej!</ButtonMobile>
          </LinkMobile>
        </ContentMobile>
        <MainImageMobile src={kamper} />
      </ContainerMobile>
    </MainContainer>
  );
};

export default Main;
