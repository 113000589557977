export const theme = {
  color: {
    white: 'hsl(0, 0%, 100%)',
    bgColor: 'hsl(0, 0%, 90%)',
    orange: 'hsl(26, 95%, 47%)',
    text: 'hsl(0, 0%, 0%)',
    textDisabled: 'hsl(0, 0%, 48%)',
    buttonBg: 'hsl(194, 100%, 41%)',
    black: 'hsl(0, 0%, 0%)',
  },

  breakpoint: {
    medium: 992,
    mobile: 768,
  },
};
