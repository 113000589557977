import { Routes, Route, HashRouter } from 'react-router-dom';
import Navigation from './components/MainPage/Navigation';
import MainPage from './components/MainPage';
import Contact from './components/Contact/Contact';

function App() {
  return (
    <HashRouter>
      <Navigation />
      <Routes>
        <Route index element={<MainPage />} />
        <Route path="home" element={<MainPage />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
