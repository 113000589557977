import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 15px;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const MainImage = styled.img`
  width: 100%;
  max-width: 1000px;
  height: auto;
  border-radius: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    display: none;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
  position: relative;
  &:hover {
    ${MainImage} {
      filter: blur(4px);
    }
  }
`;

export const HoverDiv = styled.div`
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 99;
`;

export const HoverImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
`;

export const HoverTitle = styled.h2`
  font-weight: 500;
  font-size: 32px;
  text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
`;

// Mobile

export const ContainerMobile = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    display: initial;
    position: relative;
  }
`;

export const ContentMobile = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  z-index: 99;
`;

export const LogoMobile = styled.img`
  width: 100%;
  max-width: 350px;
  height: auto;

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}px) {
    max-width: 200px;
  }
`;

export const LinkMobile = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
`;

export const ButtonMobile = styled.button`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.color.buttonBg};
  color: ${({ theme }) => theme.color.white};
  padding: 10px 16px;
  border-radius: 8px;
`;

export const MainImageMobile = styled.img`
  width: 100%;
  max-width: 700px;
  height: auto;
  border-radius: 10px;
  filter: blur(1px);
`;
