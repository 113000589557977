import styled from 'styled-components';

export const Button = styled.button`
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: 0.5s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    color: ${({ theme }) => theme.color.textDisabled};
    cursor: default;
    transform: scale(1);
  }
`;
