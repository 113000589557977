import Main from './Main';
import Footer from './Footer';
import { PageWrapper } from './styled';

function MainPage() {
  return (
    <PageWrapper>
      <Main />
      <Footer />
    </PageWrapper>
  );
}

export default MainPage;
