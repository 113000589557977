import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 15px;
  padding: 15px;
  background: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  text-align: center;
`;
