import { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Button } from '../../../common/Button/styled';
import {
  Dropdown,
  DropdownItem,
  DropdownList,
  Link,
  LinkDisabled,
  Menu,
  NavigationContainer,
  NavigationList,
  NavigationListItem,
  NavigationTitle,
  StyledNavLink,
  TitleLink,
} from './styled';

const Navigation = () => {
  const current = false;
  const [isShown, setIsShown] = useState(current);

  const clickHandle = () => {
    setIsShown((current) => !current);
  };

  return (
    <NavigationContainer>
      <NavigationTitle>
        <TitleLink to="/">A może nad morze...</TitleLink>
      </NavigationTitle>
      <Dropdown onClick={clickHandle}>
        <GiHamburgerMenu color="black" />
      </Dropdown>
      <NavigationList>
        <NavigationListItem>
          <Button>
            <StyledNavLink to="/">Główna</StyledNavLink>
          </Button>
        </NavigationListItem>
        <NavigationListItem>
          <Button disabled>O nas</Button>
        </NavigationListItem>
        <NavigationListItem>
          <Button disabled>Okolica</Button>
        </NavigationListItem>
        <NavigationListItem>
          <Link
            href="https://www.booking.com/hotel/pl/ryby-w-sieci.pl.html"
            target={'_blank'}
            rel={'noreferrer'}
            onClick={clickHandle}
          >
            <Button>Domki</Button>
          </Link>
        </NavigationListItem>
        <NavigationListItem>
          <Link
            href="https://kamperypuck.pl/1-dethleffs-just-t-7052-eb-2/"
            target={'_blank'}
            rel={'noreferrer'}
            onClick={clickHandle}
          >
            <Button>Kamper</Button>
          </Link>
        </NavigationListItem>
        <NavigationListItem>
          <Button>
            <StyledNavLink to="/contact">Kontakt</StyledNavLink>
          </Button>
        </NavigationListItem>
      </NavigationList>

      {isShown ? (
        <Menu>
          <DropdownList>
            <DropdownItem>
              <StyledNavLink to="/home" onClick={clickHandle}>
                Główna
              </StyledNavLink>
            </DropdownItem>
            <DropdownItem>
              <LinkDisabled onClick={clickHandle}>O nas</LinkDisabled>
            </DropdownItem>
            <DropdownItem>
              <LinkDisabled onClick={clickHandle}>Okolica</LinkDisabled>
            </DropdownItem>
            <DropdownItem>
              <Link
                href="https://www.booking.com/hotel/pl/ryby-w-sieci.pl.html"
                target={'_blank'}
                rel={'noreferrer'}
                onClick={clickHandle}
              >
                Domki
              </Link>
            </DropdownItem>
            <DropdownItem>
              <Link
                href="https://kamperypuck.pl/1-dethleffs-just-t-7052-eb-2/"
                target={'_blank'}
                rel={'noreferrer'}
                onClick={clickHandle}
              >
                Kamper
              </Link>
            </DropdownItem>
            <DropdownItem>
              <StyledNavLink to="/contact" onClick={clickHandle}>
                Kontakt
              </StyledNavLink>
            </DropdownItem>
          </DropdownList>
        </Menu>
      ) : null}
    </NavigationContainer>
  );
};

export default Navigation;
