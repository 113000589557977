import { Container } from './styled';

function Contact() {
  return (
    <Container>
      <p>Masz pytania lub chcesz dowiedzieć się więcej?</p>
      <p>Zadzwoń do nas!</p>
      <p>tel. 602345065</p>
      <a href="mailto:kontakt@domkirybywsieci.pl">kontakt@domkirybywsieci.pl</a>
    </Container>
  );
}

export default Contact;
