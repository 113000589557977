import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const NavigationContainer = styled.div`
  padding: 18px 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 10vh;
`;

export const NavigationTitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Caveat', sans-serif;
  font-size: 34px;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.mobile}px) {
    font-size: 26px;
  }
`;

export const TitleLink = styled(NavLink)`
  color: ${({ theme }) => theme.color.orange};
  text-decoration: none;
`;

export const NavigationList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
  list-style-type: none;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    display: none;
  }
`;

export const NavigationListItem = styled.li`
  margin: 0 15px;
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  &:last-child {
    margin-right: 0;
  }
`;

export const StyledNavLink = styled(NavLink)`
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};

  &.active {
    font-weight: 600;
  }
`;

// Dropdown mobile

export const Dropdown = styled.button`
  padding: 5px;
  outline: none;
  background-color: transparent;
  border: none;
  display: none;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    display: initial;
    position: relative;
  }
`;

export const Menu = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}px) {
    display: initial;
    position: absolute;
    z-index: 100;
    margin: 0;
    padding: 10px;
    top: 60px;
    right: 0;
    background-color: ${({ theme }) => theme.color.white};
    min-width: 100%;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const DropdownList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  list-style-position: outside;
  margin: 0;
  padding: 0;
`;

export const DropdownItem = styled.li`
  margin-bottom: 15px;

  &:last-child {
    margin: 0;
  }
`;

export const Link = styled.a`
  font-weight: 500;
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
`;

export const LinkDisabled = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.textDisabled};
`;
